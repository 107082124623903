


































import { Component, Mixins } from 'vue-property-decorator';

import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ListBooks from '@/components/ListBooks/ListBooks.vue';
import Footer from '@/components/Footer/Footer.vue';

import BookTabs from './components/BookTabs/index.vue';

import LastVideo from '@/widgets/Video/LastVideo/LastVideo.vue';
import RealtimeUsers from '@/widgets/Users/RealtimeUsers/RealtimeUsers.vue';

import BookService from '@/services/Book/BookService';

import generalBooks from '@/mixins/generalBooks';

import { Book as IBook } from '@/globalInterfaces/Book';

interface ListAllBooks {
  [degreeID: number]: Array<IBook>;
}

@Component({
  components: {
    ContainerFluid,
    PageHeader,
    ListBooks,
    LastVideo,
    RealtimeUsers,
    Footer,
    BookTabs,
  },
})
export default class Books extends Mixins(generalBooks) {
  private listAllBooks: ListAllBooks = {};
  private listCurrentBooks: Array<IBook> = [];

  private isLoading = true;

  private BookService = new BookService();

  get isMobile() {
    return this.$responsive.isMobile;
  }

  async getBooks(degreeID: number) {
    try {
      this.isLoading = true;

      const listBooks = await this.BookService.books(
        [degreeID],
        this.$store.getters.profile?.institution?.id,
      );

      this.listAllBooks[degreeID] = this.setBooks(listBooks);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os cadernos.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  checkHasDegreeBooks(degreeID: number) {
    if (!this.listAllBooks) return false;

    return Object.keys(this.listAllBooks).find(
      (degreeIDListBooks: string) => Number(degreeIDListBooks) === degreeID,
    );
  }

  async getBooksFromActiveTab(degreeID: number) {
    const hasDregreeBooks = this.checkHasDegreeBooks(degreeID);

    if (!hasDregreeBooks) await this.getBooks(degreeID);

    this.listCurrentBooks = this.filterBooksByDegree(degreeID);
  }

  filterBooksByDegree(degreeID: number) {
    return this.listAllBooks[degreeID].filter(
      (book: IBook) => book.degreeID === degreeID,
    );
  }

  setBooks(books: Array<IBook>) {
    return books.map((book: IBook) => ({
      ...book,
      id: book.idBooks,
      link: `/caderno/${book.slug}`,
      type: 'books',
      disable: false,
    }));
  }
}
